<template>
  <div>

    <div class="">

    <div class="item" :style="{backgroundImage: 'url(' + index_item.image + ')' }">
        <div class="CommonWidth">
          <div class="info">
            <div class="title">{{ index_item.title }}</div>
            <div class="exp">{{ index_item.info }}</div>
          </div>
        </div>
    </div>

      <div class="ql-snow content CommonContentWidth">
        <div class="ql-editor" v-html="articledata"></div>
      </div>

      <vue-editor v-model="editorvmodel" v-show="0"></vue-editor>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import articleIndex from '../../data/articleIndex'
import article from '../../data/article'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CaseProduct',
  components: {
    VueEditor
  },
  data(){
      return {
          index_item:null,
          articleIndex: [],
          article: [],
          title: null,
          id: null,
          articledata: null,
          editorvmodel: null
      }
  },
  created() {
    this.articleIndex = articleIndex
    this.article = article
    this.id = this.$route.params.id
    this.search_index(this.id)
  },
  methods:{
      search_index(ids){
        this.articleIndex.product.forEach((item,index) =>{  
            // console.log(item)
            if(item.id == parseInt(ids)){
                this.index_item = item
                console.log(item)
                this.title = item.title
                this.search_data(item.id)
            }
            index
            // console.log(index)
        });
      },
      search_data(ids){
          this.articledata = article['product'][ids]
      }
    }
}
</script>
<style lang="scss" scoped>
.item {
  margin-top: 0px;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flow-root;
  .info{
    margin-top: 140px;
    font-size: 48px;
    color: #ffffff;
    .title{
      text-align: center;
      font-size: 48px;
    }
    .exp{
      text-align: center;
      margin-top: 10px;
      font-size: 18px;
    }
  }
}

.content{
  margin-bottom: 200px;
  margin-top: 50px;
}

@media screen and (max-width: 850px) {
}
</style>